<section id="about-me" class="bg-gray-main rounded-2xl text-white border-b-2 border-silver">
  <div class="container">
    <article class="prose max-w-none text-white prose-invert prose-h2:underline prose-h2:text-center">
      <div class="flex justify-center"><img class="rounded-full h-44" src="assets/Profilbild_eckig.webp " alt="Datenretter"></div>
      <h4>
        Meine Ziele in der Datenrettung sind Qualität, Fairness und Transparenz.
      </h4>
      <p></p>
      <h4>1. Schaffen von idealen Voraussetzungen als Datenretter:</h4>
      <p>Ich bin mit meinem Know-how und Equipment als <strong>Datenretter</strong> breit aufgestellt. Darunter ist mir z.B. das Arbeiten unter <strong>DIN 14644-1 ISO 5</strong> Reinraumklasse möglich.
        <br>Angeboten wird nicht nur die Datenrettung von schwierigen Festplatten- und NAND-Fällen, sondern auch die professionelle SSD- und RAID-Datenrettung.<br>
        Ich bin zudem in der Lage die interne Firmware ("Servicearea") der jeweiligen Speicher zu bearbeiten und zu umgehen,<br>
        was bei einer Datenrettung v. Festplatten mindestens genauso essentiell wie eine mechanische Reparatur sein kann.
      </p>
      <h4>2. Öffentliche Preise und nur nach Erfolg:</h4>
      <p>Alle meine Fälle werden zu einem öffentlichen "all-inclusive" Festpreis bearbeitet. Bei Misserfolg entstehen keine Gebühren.
      </p>
      <h4>3. Tipps und Einblicke:</h4>
      <p>Fragen Sie gerne nach wie und mit welchem Werkzeug die Datenrettung durchgeführt wird. <br>Gerne berate ich Sie zudem kostenfrei zur Einrichtung einer zukünftigen Backupstrategie zur Datensicherung.</p>
        <h4>
          Entstehungsgeschichte als <strong>Datenrettungsdienst</strong>:
        </h4>
      <div class="relative flex pb-8">
        <div class="absolute inset-0 flex items-center justify-center w-12 h-full">
          <div class="w-1 h-full bg-white pointer-events-none"></div>
        </div>

        <div class="top-44 relative z-10 inline-flex items-center justify-center
        flex-shrink-0 w-12 h-12 text-black rounded-full bg-white">
          2018
        </div>
        <div class="flex-grow pl-4">
          <p>
            Bereits zu meinem Abitur, als auch während meines Ingenieurstudiums, arbeitete ich bei einem
            mittelständischen
            Elektronikdienstleister, bei dem ich das ESD-geschützte Arbeiten an elektronischen Baugruppen, z.B. Löten an
            SMD
            und THT Bauteilen lernen, als auch für optische Systeme unter Reinraumbedingungen arbeiten durfte.
            <br><br>
            Meinen ersten Auftrag zu einer Datenrettung erhielt ich 2018.<br>
            Eine Festplatte aus dem Freundeskreis bei der ein Angebot einer professionellen Datenrettung im
            vierstelligen
            Betrag vorlag, was für den Besitzer nun mal zu teuer war.

            Ich kannte mich schon immer gut mit allgemeiner IT aus und half gerne Freunden und Verwandten, die damit
            Schwierigkeiten hatten. So kam diese Festplatte zu mir. Damals mit noch nicht professionellen Mitteln,
            sondern
            mit einer simplen Linux Software war es tatsächlich möglich gewesen, die Daten zu retten.

            Darüber erschüttert, dass das Angebot für die Datenrettung dieser Festplatte so teuer gewesen wäre, fing ich an,
            meine
            Dienste günstig, bereits damals zu einem Festpreis nach Erfolg, unter der Webseite ammerseedatenrettung.de
            anzubieten. 
            
            <br><br>
          </p>
          <h4>Der Einstieg in die professionelle <strong>NAND-Datenrettung</strong>:</h4> 

          <p>
            
            Ich konnte mit meinen damaligen begrenzten Mitteln bereits einige Kunden glücklich machen, doch kamen auch Aufträge, bei denen ich ohne professionelle Mittel keine Lösung erzielen
            konnte.<br>
    
            Mit der Situation unzufrieden fing ich an zu recherchieren und nahm mein komplettes von Studentenjobs erspartes
            Geld in die Hand um mein erstes Produkt von ACE Lab zu erwerben. Den PC-3000 Flash.
    
            Hiermit konnte ich nun NAND-Speicher bearbeiten, die vom Computer nicht mehr erkannt worden sind und somit mit
            reiner Software nicht lösbar waren! Ein großer Fortschritt.
        
          
          </p>

        </div>
      </div>
      <div class="relative flex pb-8">
        <div class="absolute inset-0 flex items-center justify-center w-12 h-full">
          <div class="w-1 h-full bg-white pointer-events-none"></div>
        </div>
        <div class="top-20 relative z-10 inline-flex items-center justify-center
        flex-shrink-0 w-12 h-12 text-black rounded-full bg-white">
          2019
        </div>
        <div class="flex-grow pl-4">
          <h4> Der Einstieg in die professionelle Datenrettung für Festplatten: </h4>
          <p>
            Auch aufgrund der Kritik eines Kunden, dass ich die Daten seiner Festplatte trotz keiner klackernden Geräusche mit meinen
            damaligen Mitteln nicht lösen konnte, und auch wegen meines persönlichen Ehrgeizes, entschied ich mich Anfang 2019 diesmal meinen
            damaligen kompletten Besitz, zwei Motorräder, zu verkaufen, um mir entsprechendes Equipment anzuschaffen.
    
            Dazugekommen sind eine Laminar Flowbox, die es mir fortan erlaubte als Datenrettungsdienst unter Reinraumbedingungen zu arbeiten, als
            auch das PC-3000 UDMA von ACE Lab, womit ich nun die technische Unterstützung auf Herstellerebene diverser
            Festplattenmodelle erhielt.
          </p>
        </div>




      
      </div>

      <div class="relative flex pb-8">
        <div class="absolute inset-0 flex items-center justify-center w-12 h-full">
          <div class="w-1 h-full bg-white pointer-events-none"></div>
        </div>
        <div class="top-28 relative z-10 inline-flex items-center justify-center
        flex-shrink-0 w-12 h-12 text-black rounded-full bg-white">
          Heute
        </div>
        <div class="flex-grow pl-4">
         
          <h4>Die Situation heute:</h4>
         
          <p>
            
        Aktuell habe ich 1433 Datenrettungsfälle (Stand: 06.04.24) erfolgreich bearbeitet.
        Mit diesen kamen neue Herausforderungen, die auch neue Lösungen hervorbrachten.

        Inzwischen bin ich oftmals auch in der Lage in Fällen bei denen ein vorheriger professioneller Bearbeiter bereits
        aufgegeben hat, erfolgreich bearbeiten zu können, um z.B. zumindest eine Teildatenrettung zu erzielen.

        Herausforderungen durch Datenrettung von Festplatten mit beschädigten / verkratzten Oberflächen oder Modelle
        wie z.B. moderne SMR WD-Speicher mit SED Verschlüsselung stellen mittlerweile den Datenrettungsalltag dar.


<br><br> <br>
        Ich hoffe, dass Sie mein Angebot als fair empfinden werden und ich Sie mit meiner Leistung als Datenretter überzeugen kann.
<br>
        Falls Fragen bestehen rufen Sie am besten unter 0841 12840705 an oder schreiben Sie eine Email an:
        info@jungbauerdatenrettung.de
          </p>
        </div>
      </div>
    </article>
  </div>
</section>



