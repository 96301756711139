import {BrowserModule, Meta} from '@angular/platform-browser';
import { NgModule, ViewChild } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { ClickOutsideModule } from 'ng-click-outside';
import { PricesComponent } from './components/prices/prices.component';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SiteNotFoundComponent } from './components/site-not-found/site-not-found.component';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { HomeModule } from './components/home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localeDe);
@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    PricesComponent,
    SiteNotFoundComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxPageScrollCoreModule.forRoot({ duration: 700, scrollOffset: 80 }),
    ClickOutsideModule,
    ScullyLibModule,
    HomeModule,
    BrowserAnimationsModule,
  ],
  providers: [Meta],
  bootstrap: [AppComponent],
})
export class AppModule {
  @ViewChild(NavigationComponent) nav;
}
